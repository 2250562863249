<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-8">
                <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1 class="mb-0"><strong>{{getTitleUI}}</strong></h1>
                </div>
                <button
                  class="btn th-custom-color px-4 rounded ml-3"
                  @click="irACrearMedico"
                  :disabled="!hasPermisionNuevoMedico"
                >
                  Nueva
                </button>
              </div>
              <div class="col-sm-3 offset-sm-1">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter" :checked="estado" v-model="estado">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select v-model="por_pagina" :options="pagina_opciones"></b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" v-model="busqueda"
                    autocomplete="off" placeholder="Buscar" @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
            </div>
            <!-- Tabla -->
            <b-table class="table table-striped table-hover"
              :fields="getFieldsTable"
              :items="listaTabla"
              :current-page="pagina_actual"
              :per-page="0"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
            >
              <template v-if="estado" #cell(editar)="items">
                <router-link v-if="hasPermisionModificarMedico"
                  :to="{ name: 'medico-edit', params: {id: encrypt(items.item.id)}}"
                >
                  <i
                    class="nav-icon i-Pen-5 text-success font-weight-bold"
                    style="cursor: pointer"
                  ></i>
                </router-link>
                <i v-else
                  class="nav-icon i-Pen-5 font-weight-bold"
                  style="cursor: pointer"
                ></i>
              </template>
              <template #cell(ver)>
                <i
                  v-if="hasPermisionVerMedico"
                  class="nav-icon i-Eye font-weight-bold"
                  :class="hasPermisionVerMedico? 'text-warning':''"
                  style="cursor: pointer"
                ></i>
              </template>
              <template v-if="estado" #cell(eliminar)="row">
                <i
                  class="nav-icon i-Close-Window font-weight-bold"
                  :class="hasPermisionEliminarMedico? 'text-danger':''"
                  style="cursor: pointer"
                  @click="onClickEliminarIcon(row.item.id)"
                ></i>
              </template>
              <template v-if="extrafacturaElectronica && hasPermissionMedicoTercero" #cell(siat_sync)="row">
               <i
                v-if="row.item.nit"
                :class="{
                  'nav-icon': true,
                  'i-Diploma-2': row.item.modalidad_facturacion_medico_id === 2,
                  'i-Monitor-2': row.item.modalidad_facturacion_medico_id === 1,
                  'font-weight-bold': true,
                  'text-success': true
                }"
                style="cursor: pointer"
                @click="openModalSincronizarFactura(row.item)"
                @mouseover="updateTooltipContent(row)"
                @mouseleave="clearTooltipContent"
                :title="tooltipContent"
                class="mr-1"
              ></i>
              <i class="nav-icon i-Pen-4 text-success font-weight-bold mr-1"
                v-if="row.item.is_factura_manual"
                title="Manual"
                style="cursor: pointer;"></i>
              <i class="nav-icon i-Duplicate-Layer text-success font-weight-bold"
                v-if="row.item.is_sin_factura"
                title="Sin Factura"
                style="cursor: pointer;"></i>
              </template>
              <template #cell(celular)="it">
                <a :href="changePhone(it.item.phone)" target="_blank">{{ it.item.celular }}</a>
              </template>
            </b-table>
            <!-- Fin Tabla -->
            <!-- Paginacion -->
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  class="my-0 float-right"
                ></b-pagination>
              </div>
            </div>
            <!-- Fin Paginacion -->
          </div>
        </div>
      </div>
    </div>
    <modalSyncFactura
      ref="modalFacturacionElectronica"
      :medico="medicoSelected"
      @updateListaMedicos="listaTablas"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { FUNCIONALIDADES } from '@/utils/globalConventions';
import modalSyncFactura from '../components/modalSyncFactura.vue';

export default {
  name: 'index',
  components: {
    modalSyncFactura,
  },
  data() {
    return {
      tooltipContent: '',
      especialidad: {
        title: 'Especialidad',
        holder: 'Ingrese una Especialidad',
        classButton: 'btn btn-white border border-light px-4',
        nameUrl: 'especialidad',
        modal: 'modal-expecialidad',
        data: 'especialidad',
      },
      clinicaExterna: {
        title: 'Clinica Externa',
        holder: 'Ingrese una Clinica Externa',
        classButton: 'btn btn-white border border-light ml-4',
        nameUrl: 'clinicaexterna',
        modal: 'modal-clinicaExterna',
        data: 'clinica_externa',
      },
      medicoExterno: {
        title: 'Medico Externo',
        holder: 'Ingrese un Medico Externo',
        classButton: 'btn btn-white border border-light px-4 ml-4',
        nameUrl: 'medicoexterno',
        modal: 'modal-medicoExterno',
        data: 'medico_externo',
      },
      medicoSelected: null,
    };
  },
  created() {
    this.listaTablas();
  },
  methods: {
    ...mapActions('MedicoList', ['filtrarBusqueda', 'listaTablas', 'deleteItems']),
    changePhone(payload) {
      const url = process.env.VUE_APP_WP_BASE + payload;
      return url;
    },
    openModalSincronizarFactura(medico) {
      if (medico.modalidad_facturacion_medico_id === 2) {
        this.medicoSelected = medico;
        this.$bvModal.show('modal-sincronizar-factura');
      }
    },
    encrypt(id) {
      return btoa(id);
    },
    irACrearMedico() {
      this.$router.push({ name: 'medico-create' });
    },
    onClickEliminarIcon(id) {
      if (this.hasPermisionEliminarMedico) {
        this.deleteItems(id);
      }
    },
    updateTooltipContent(row) {
      this.tooltipContent = row.item.modalidad_facturacion_medico_id === 2
        ? 'Electrónica' : 'Computarizada';
    },
    clearTooltipContent() {
      this.tooltipContent = '';
    },
  },
  computed:
  {
    ...mapFields('main', [
      'extrafacturaElectronica',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Inactivos';
    },
    ...mapState('MedicoList', [
      'estado',
      'busqueda',
      'pagina_actual',
      'por_pagina', // 50
      'totalFilas',
      'pagina_opciones',
      'sortBy',
      'sortDesc',
      'fields',
      'listaTabla',
      'delayTimeout',
    ]),
    ...mapGetters('main', [
      'getFuncionalidades',
      'hasPermisionNuevoMedico',
      'hasPermisionModificarMedico',
      'hasPermisionVerMedico',
      'hasPermisionEliminarMedico',
      'hasPermissionMedicoTercero',
    ]),
    getTitleUI() {
      const ITEM = this.getFuncionalidades.find((el) => el.id === FUNCIONALIDADES.MEDICO);
      if (ITEM !== null) {
        return ITEM.descripcion;
      }
      return '';
    },
    getFieldsTable() {
      let customFields = this.fields;
      if (this.hasPermisionVerMedico === false) {
        customFields = customFields.filter((obj) => (
          obj.key !== 'ver'
        ));
      }
      return customFields;
    },
    pagina_actual: {
      get() {
        return this.$store.state.MedicoList.pagina_actual;
      },
      set(data) {
        this.$store.commit('MedicoList/togglePaginaActual', data);
        this.$store.dispatch('MedicoList/listaTablas');
      },
    },
    estado: {
      get() {
        return this.$store.state.MedicoList.estado;
      },
      set(data) {
        this.$store.commit('MedicoList/toggleEstado', data);
        this.$store.dispatch('MedicoList/listaTablas');
      },
    },
    busqueda: {
      get() {
        return this.$store.state.MedicoList.busqueda;
      },
      set(data) {
        this.$store.commit('MedicoList/toggleBusqueda', data);
      },
    },
    por_pagina: {
      get() {
        return this.$store.state.MedicoList.por_pagina;
      },
      set(data) {
        this.$store.commit('MedicoList/togglePorPagina', data);
        this.$store.dispatch('MedicoList/listaTablas');
      },
    },
    sortBy: {
      get() {
        return this.$store.state.MedicoList.sortBy;
      },
      set(data) {
        this.$store.commit('MedicoList/toggleSortBy', data);
      },
    },
    sortDesc: {
      get() {
        return this.$store.state.MedicoList.sortDesc;
      },
      set(data) {
        this.$store.commit('MedicoList/togglgeSortDesc', data);
      },
    },
  },
};
</script>

<style scoped>
.custom-top-padding {
  padding-top: 5.53px;
}
</style>
